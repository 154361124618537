import styled from "@emotion/styled";
import Image, { FluidObject } from "gatsby-image";
import React, { useMemo, useState } from "react";
import Lightbox from "react-images";
import { lowerCase, upperFirst } from "lodash";

export const Gallery: React.FC<{
  images: {
    id: string;
    name: string;
    image: {
      fluid: FluidObject;
    };
  }[];
  hideNames?: boolean;
}> = ({ images, hideNames = false }) => {
  const [currentImage, setCurrentImage] = useState<number | null>(null);
  const lightboxImages = useMemo(
    () =>
      images.map(i => ({
        caption: !hideNames ? upperFirst(lowerCase(i.name)) : undefined,
        src: i.image.fluid.src,
        srcSet: i.image.fluid.srcSet.split(",\n"),
      })),
    [images, hideNames]
  );

  return (
    <>
      <Lightbox
        currentImage={currentImage}
        images={lightboxImages}
        isOpen={currentImage !== null}
        onClickPrev={() => setCurrentImage(currentImage => currentImage - 1)}
        onClickNext={() => setCurrentImage(currentImage => currentImage + 1)}
        onClose={() => setCurrentImage(null)}
        backdropClosesModal
      />
      <Wrapper>
        {images.map((image, idx) => (
          <ImageWrapper key={image.id} onClick={() => setCurrentImage(idx)}>
            <Image fluid={image.image.fluid} />
          </ImageWrapper>
        ))}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
`;

const ImageWrapper = styled.div`
  height: 120px;
  overflow-y: hidden;
  cursor: pointer;

  & > * {
    height: 100%;
  }
`;
